import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import myCustomFunction from "./scripts/customScript";
import Header from "./components/Header";
import Main from "./components/Main";
import VideoDetail from "./components/VideoDetail";
import Footer from "./components/Footer";
import "./css/reset.css";
import "./css/styles.css";
import BASE_URL from "./config.js";

function App() {
  console.log("App.js is running...");

  const [VideoPlayerComponent, setVideoPlayerComponent] = useState(null);
  const [videoPlayerName, setVideoPlayerName] = useState("");
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const isMounted = useRef(false);
  const hasFetched = useRef(false);

  useEffect(() => {
    console.log("Running useEffect in App.js...");
    isMounted.current = true;
    myCustomFunction();

    const controller = new AbortController();

    const loadVideoPlayer = async () => {
      try {
        const playerName = process.env.REACT_APP_VIDEO_PLAYER || "VideoPlayer";
        console.log(`Loading video player: ${playerName}`);
        setVideoPlayerName(playerName);

        const module = await import(`./components/${playerName}`);
        if (isMounted.current) {
          console.log(`${playerName} has been loaded.`);
          setVideoPlayerComponent(() => module.default);
        }
      } catch (error) {
        console.error("Error loading VideoPlayer component:", error);
      }
    };

    const fetchAllVideos = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        console.log(`Fetching videos from ${BASE_URL}/videos`);
        setLoading(true);
        const response = await fetch(`${BASE_URL}/videos`, {
          signal: controller.signal,
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch videos: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(`Fetched ${data.length} videos.`);
        if (isMounted.current) {
          setVideos(data);
          setFilteredVideos(data);
          setCurrentVideoId(data.length > 0 ? data[0].id : null);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching videos:", error);
          setFetchError(error.message);
        }
      } finally {
        if (isMounted.current) setLoading(false);
      }
    };

    loadVideoPlayer();
    fetchAllVideos();

    return () => {
      console.log("Cleaning up in App.js...");
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const handleSearch = async (query) => {
    console.log(`Search initiated with query: ${query}`);

    // If the query is empty, reset the filteredVideos to all videos.
    if (!query.trim()) {
      setFilteredVideos(videos);
      console.log("Search cleared. Resetting to all videos.");
      return;
    }

    try {
      // Encode the query to handle special characters.
      const encodedQuery = encodeURIComponent(query.trim());
      console.log(`Fetching search results for "${encodedQuery}"...`);

      const response = await fetch(`${BASE_URL}/tags/videos/${encodedQuery}`);
      if (!response.ok) {
        throw new Error(`Failed to search videos: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(`Search returned ${data.length} result(s).`);

      // Update filteredVideos with the search results.
      setFilteredVideos(Array.isArray(data) ? data : []);

      // Optionally, if no videos are returned, clear the currentVideoId.
      if (!Array.isArray(data) || data.length === 0) {
        setCurrentVideoId(null);
      }
    } catch (error) {
      console.error("Error searching videos:", error);
      // Clear search results on error.
      setFilteredVideos([]);
      setCurrentVideoId(null);
    }
  };

  if (fetchError) {
    console.error(`Error encountered: ${fetchError}`);
    return <div>Error: {fetchError}</div>;
  }
  if (!VideoPlayerComponent) return <div>Loading Video Player...</div>;
  if (loading) return <div>Loading videos...</div>;

  return (
    <Router>
      <div className="App">
        <Header onSearch={handleSearch} />
        <main>
          <div
            style={{
              padding: "1.5px",
              backgroundColor: "#f8f9fa",
              textAlign: "center",
            }}
          >
            <h3>Currently using: {videoPlayerName}</h3>
          </div>

          <div title="This is a test tooltip">
            Hover over this text to see a tooltip
          </div>

          <VideoPlayerComponent
            onVideoChange={(id) => {
              console.log(`Video changed to ID: ${id}`);
              setCurrentVideoId(id);
            }}
            videos={filteredVideos}
          />

          <Routes>
            <Route
              path="/"
              element={
                <Main currentVideoId={currentVideoId} onSearch={handleSearch} />
              }
            />
            <Route
              path="/video/:id"
              element={
                <VideoDetail
                  onLoad={() => console.log("VideoDetail component loaded.")}
                />
              }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
