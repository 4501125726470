import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";
import BASE_URL from "../config"; // ✅ Correct

function VideoDetail() {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMounted = useRef(true);
  const hasFetched = useRef(false);
  const controller = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    controller.current = new AbortController();

    const fetchVideo = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;

      try {
        console.log(`Fetching video details for ID: ${id}`);
        const response = await fetch(`${BASE_URL}/videos/${id}`, {
          signal: controller.current.signal,
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch video: ${response.statusText}`);
        }

        const data = await response.json();
        if (isMounted.current) {
          setVideo(data.video || null);
          setTags(Array.isArray(data.tags) ? data.tags : []);
        }
      } catch (error) {
        if (error.name !== "AbortError" && isMounted.current) {
          console.error("Error fetching video:", error);
          setError(error.message);
        }
      } finally {
        if (isMounted.current) setLoading(false);
      }
    };

    fetchVideo();

    return () => {
      isMounted.current = false;
      if (controller.current) controller.current.abort();
    };
  }, [id]);

  const videoPlatforms = {
    youtube: (video) => (
      <YouTube
        videoId={video.platform_id}
        opts={{ height: "480", width: "854", playerVars: { autoplay: 1 } }}
        loading="lazy"
      />
    ),
    dailymotion: (video) => (
      <iframe
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          overflow: "hidden",
        }}
        frameBorder="0"
        type="text/html"
        src={`https://www.dailymotion.com/embed/video/${video.platform_id}?autoplay=1`}
        allowFullScreen
        title="Dailymotion Video Player"
        sandbox="allow-same-origin allow-scripts allow-popups allow-presentation"
        allow="autoplay; fullscreen; clipboard-write"
        loading="lazy"
      ></iframe>
    ),
    SPB: (video) => (
      <iframe
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          overflow: "hidden",
        }}
        frameBorder="0"
        type="text/html"
        src={`https://spankbang.com/${video.platform_id}/embed/?autoplay=1`}
        allowFullScreen
        title="SPB Video Player"
        sandbox="allow-same-origin allow-scripts allow-popups allow-presentation"
        allow="autoplay; fullscreen; clipboard-write"
        loading="lazy"
      ></iframe>
    ),
  };

  if (loading) return <p>Loading video...</p>;
  if (error)
    return (
      <p style={{ color: "red", fontWeight: "bold" }}>
        Error loading video: {error}
      </p>
    );

  return (
    <div>
      <h1>{video?.title || "Video Not Found"}</h1>

      {/* Render video player dynamically */}
      {video?.platform && video.platform_id ? (
        videoPlatforms[video.platform] ? (
          videoPlatforms[video.platform](video)
        ) : (
          <p>Unsupported platform</p>
        )
      ) : (
        <p>Video not available</p>
      )}

      {/* Display Tags */}
      <div className="tags-container">
        {tags.length > 0 ? (
          tags.map((tag) => (
            <span key={tag.id} className="tag">
              {tag.name}
            </span>
          ))
        ) : (
          <p>No tags available</p>
        )}
      </div>
    </div>
  );
}

export default VideoDetail;
