import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BASE_URL from "../config"; // ✅ Correct

function Main({ currentVideoId: propVideoId, onSearch }) {
  const { id } = useParams();
  const currentVideoId = id ? parseInt(id, 10) : propVideoId;
  console.log("Current video ID in Main:", currentVideoId);

  const [globalTags, setGlobalTags] = useState([]);
  const [videoTags, setVideoTags] = useState([]);
  const [tagDisplayMode, setTagDisplayMode] = useState("global"); // "global" | "selected" | "video"
  const [selectedTag, setSelectedTag] = useState(null);
  const [error, setError] = useState(null);

  // ✅ Fetch all global tags on first page load
  useEffect(() => {
    const fetchGlobalTags = async () => {
      try {
        console.log("Fetching all global tags");
        const response = await fetch(`${BASE_URL}/tags`);
        if (!response.ok) throw new Error(`Failed to fetch global tags`);
        const data = await response.json();
        setGlobalTags(data);
      } catch (err) {
        console.error("Error fetching global tags:", err);
        setError(err.message);
      }
    };
    fetchGlobalTags();
  }, []);

  // ✅ Fetch video-specific tags when a video is selected
  useEffect(() => {
    if (!currentVideoId) return;
    const fetchVideoTags = async () => {
      try {
        console.log(`Fetching tags for video ID: ${currentVideoId}`);
        const response = await fetch(
          `${BASE_URL}/tags/video/${currentVideoId}`
        );
        if (!response.ok) throw new Error(`Failed to fetch video tags`);
        const data = await response.json();
        setVideoTags(data);
        setTagDisplayMode("video"); // Show only this video's tags
      } catch (err) {
        console.error("Error fetching video tags:", err);
        setError(err.message);
      }
    };
    fetchVideoTags();
  }, [currentVideoId]);

  // ✅ Clicking a tag updates thumbnails and displays only that tag
  const handleTagClick = (tag) => {
    console.log("Tag clicked:", tag);
    setSelectedTag(tag);
    setTagDisplayMode("selected");
    onSearch(tag.name); // Filter videos to show only ones with this tag
  };

  // ✅ Clicking "Clear Filter" resets the view to global tags
  const handleClearTagFilter = () => {
    setSelectedTag(null);
    setTagDisplayMode("global");
    onSearch(""); // Reset the video list
  };

  return (
    <main>
      {/* ✅ Tag Display Section */}
      <section className="video-tags">
        {error ? (
          <p style={{ color: "red", fontWeight: "bold" }}>Error: {error}</p>
        ) : tagDisplayMode === "global" ? (
          globalTags.length > 0 ? (
            globalTags.map((tag) => (
              <button
                key={tag.id}
                className="tag-badge"
                onClick={() => handleTagClick(tag)}
                type="button"
              >
                {tag.name}
              </button>
            ))
          ) : (
            <p>No tags available</p>
          )
        ) : tagDisplayMode === "selected" ? (
          <div>
            <button
              className="tag-badge"
              type="button"
              onClick={handleClearTagFilter}
            >
              {selectedTag?.name}
            </button>
            <button onClick={handleClearTagFilter} type="button">
              Clear Filter
            </button>
          </div>
        ) : tagDisplayMode === "video" ? (
          videoTags.length > 0 ? (
            videoTags.map((tag) => (
              <span key={tag.id} className="tag-badge">
                {tag.name}
              </span>
            ))
          ) : (
            <p>No tags for this video</p>
          )
        ) : null}
      </section>
    </main>
  );
}

export default Main;
